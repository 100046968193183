import { CSSProperties, ImgHTMLAttributes, forwardRef, useMemo } from 'react';
import { ContentfulImage } from '@ts/contentful';

type ContentfulPictureProps = ImgHTMLAttributes<HTMLImageElement> & {
	alt?: string;
	/**
	 * Provides both mobile and desktop images for the <picture> element to choose from.
	 * @required
	 */
	images: { mobile: ContentfulImage; desktop: ContentfulImage };
	/**
	 * If 'avif' is unsupported in browser, will default to 'webp'.
	 * @default 'avif'
	*/
	format?: 'avif' | 'webp' | 'jpg' | 'png';
	/**
	 * @important Only works with JPG format
	 * Allows for progressive loading of JPG images.
	*/
	progressiveJpeg?: boolean;
	/**
	 * Provides a hint to the browser as to how it should decode the image.
	 * Set to 'async' is image is not visible on page load.
	 * @default 'auto'
	*/
	decoding?: 'sync' | 'async' | 'auto';
	width?: number;
	height?: number;
	/**
	 * Can be any number between 0-100.
	 * @default 90
	 * @note If quality is set to 100, the image will be lossless. Otherwise 99 will initiate lossy compression.
	 */
	quality?: number;
	/**
	 * Provides way to override width query param in Images API URL generation.
	 * In some cases, larger + wider desktop images suffer quality issues that are caused by image compression
	 * via the width query param.
	 * @default false
	 */
	ignoreWidthParam?: boolean;
	style?: Partial<CSSProperties>;
	className?: string;
	fetchPriority?: 'auto' | 'high' | 'low';
};

const SIZES = {
	desktop: [1920, 769],
	mobile: [551, 375],
};

function qualityThreshold(qNum: number): number {
	if (qNum <= 0) return 0;
	if (qNum >= 100) return 100;
	return qNum;
}

function configureUrl({ url, format, quality, width, height, progressiveJpeg }) {
	//--- Default Config ---//
	const imageURL = new URL(url);
	imageURL.searchParams.set('fm', format);
	imageURL.searchParams.set('q', qualityThreshold(quality).toString());

	//--- Options ---//
	if (width) imageURL.searchParams.set('w', width.toString());
	if (height) imageURL.searchParams.set('h', height.toString());
	if (progressiveJpeg && format === 'jpg') imageURL.searchParams.set('fl', 'progressive');

	return imageURL.href;
}

type FormattedSrcSets = {
	[key: string]: Array<string>; //--- 'format' prop is always included as a key ---//
	webp: Array<string>;
};
function generateSrcSets({ format, height = null, url, quality, progressiveJpeg, sizes, ignoreWidthParam }): FormattedSrcSets {
	const srcSets = { [format]: [], webp: [] };

	Object.keys(srcSets).map((key) => {
		srcSets[key] = Object.values(sizes)
			.map((value) => {
				return configureUrl({
					width: ignoreWidthParam ? undefined : value,
					height,
					url,
					format,
					quality,
					progressiveJpeg,
				}) + ` ${value}w`
			}
			)
	});
	return srcSets;
}

/**
 * Generates <source> elements for the <picture> element in descending order of size.
 * This allows the browser to choose the best image for the current viewport based on the generated 'minWidth' and 'maxWidth' media queries.
 * 
 * @note <source> will be chosen upon the first media query that matches, so descending order is critical (desktop ---> mobile)
 * 
 * @param format 
 * @param mobileSrcSet 
 * @param desktopSrcSet 
 * @returns Array<JSX.Element>
 */
function generateSourcesByFormat({ format, mobileSrcSet, desktopSrcSet }: { format: string, mobileSrcSet: FormattedSrcSets, desktopSrcSet: FormattedSrcSets }): Array<JSX.Element> {
	if (!format) return null;
	const breakpoint = 769;
	const sizeEntries = Object.entries(SIZES);

	return sizeEntries.flatMap(
		([key, values]) => {
			return values.flatMap((size, index) => {
				let srcset = key === 'mobile' ? mobileSrcSet[format][index] : desktopSrcSet[format][index];
				const minWidth = (index === 0 && key == 'mobile') ? '' : `(min-width: ${size}px)`;

				//--- The breakpoint determines when we switch over to higher quality desktop images ---//
				//--- (ex: 769 & 991 === 1920 quality) This provides granular quality and download size control between mobile/desktop ---//
				if (size >= breakpoint) {
					srcset = desktopSrcSet[format][0];
					size = values[0]
				} else 
				if (size < breakpoint) {
					srcset = mobileSrcSet[format][0];
					size = values[0]
				}

				return (
					<source
						key={`${key + size}-${format}-${index}`}
						type={`image/${format}`}
						srcSet={srcset}
						media={`${minWidth}`} //--- Last media query is always min-width ---//
						width={size}
					/>
				)
			})
		}
	)
}

/**
 * Specialized ContentfulPicture component that handles CDN URL generation for mobile and desktop images based on
 * Contentful's Image API.
 * 
 * @note Certain properties available in the API have been purposefully omitted to simplify the component.
 * @imageAPI https://www.contentful.com/developers/docs/references/images-api
 */
const ContentfulPicture = forwardRef<HTMLImageElement, ContentfulPictureProps>(
	(
		{
			alt,
			images,
			onLoad,
			decoding = 'auto',
			fetchPriority = 'auto',
			format = 'avif',
			ignoreWidthParam = false,
			loading = 'eager',
			progressiveJpeg = false,
			width: widthOverride = undefined,
			height: heightOverride = undefined,
			quality = 90,
			className,
			...rest
		},
		ref
	) => {

		const { mobile = undefined, desktop = undefined } = images;

		if (mobile === undefined || desktop === undefined) {
			throw new Error('Both mobile and desktop images are required for the <picture> element to work correctly. If you only have one image, please add that image to both the mobile and desktop fields.');
		}

		//--- Sources Generation with Multiple Formats ---//
		const sourcesJsx = useMemo(() => {
			const formats = new Set([format, 'webp']); //--- 'webp' is always supported as a fallback; Set overrides 'format' prop if it is 'webp' as well ---//

			return [...formats].map(fm => {
				const args = {
					format: fm,
					mobileSrcSet: generateSrcSets({ url: mobile.url, format: fm, quality, progressiveJpeg, sizes: SIZES['mobile'], ignoreWidthParam }),
					desktopSrcSet: generateSrcSets({ url: desktop.url, format: fm, quality, progressiveJpeg, sizes: SIZES['desktop'], ignoreWidthParam }),
				}
				return generateSourcesByFormat(args)
			})

		}, [format, mobile.url, quality, progressiveJpeg, ignoreWidthParam, desktop.url]);

		return (
			<picture data-testid='picture' className={className}>
				{sourcesJsx}
				<img
					ref={ref}
					alt={alt || mobile.title}
					sizes='100vw'
					width={widthOverride || mobile.width}
					height={heightOverride || mobile.height}
					decoding={decoding}
					loading={loading}
					{...(onLoad && { onLoad })}
					{...(fetchPriority && { fetchpriority: 'true' })}
					{...rest}
				/>
			</picture>
		);
	});

ContentfulPicture.displayName = 'ContentfulPicture';

export default ContentfulPicture;
